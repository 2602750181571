<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="5">
              <v-skeleton-loader
                v-if="dataIsLoading"
                elevation="2"
                type="card, list-item-avatar-three-line, list-item-avatar-three-line"
              ></v-skeleton-loader>
              <v-card v-else class="pt-4">
                <v-card-title class="justify-center flex-column">
                  <v-avatar
                    size="160"
                    :color="
                      $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'
                    "
                    rounded
                    class="mb-2"
                  >
                    <!-- TODO: Correct photoUrl -> resolution  -->
                    <v-img
                      v-if="user.photoUrl"
                      contain
                      :src="user.photoUrl"
                    ></v-img>
                    <v-icon v-else size="82">mdi-account-circle</v-icon>
                  </v-avatar>

                  <span
                    >{{ user.title }} {{ user.firstName }}
                    {{ user.lastName }}</span
                  >

                  <personnel-record-status-chip
                    :status="user.status"
                    class="mt-2"
                  >
                  </personnel-record-status-chip>

                  <!-- <span class="body-2 text--secondary">{{
                    user.dutyPosition.title
                  }}</span> -->
                </v-card-title>

                <!-- <v-card-text class="justify-center mt-6">
                  <v-card outlined class="mb-4">
                    <v-card-text>
                      <div class="d-flex align-center me-8">
                        <v-avatar color="red lighten-5" rounded class="me-4">
                          <v-icon size="22" color="red"> mdi-circle </v-icon>
                        </v-avatar>

                        <div>
                          <h3 class="text-xl font-weight-medium mb-n1">
                            {{ getAge(user.birthdate) }}
                          </h3>
                          <span>Alter</span>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>

                  <v-card outlined class="mb-4">
                    <v-card-text>
                      <div class="d-flex align-center me-8">
                        <div class="d-flex align-center me-4">
                          <v-avatar color="red lighten-5" rounded class="me-4">
                            <v-icon size="22" color="red">
                              mdi-timeline-clock
                            </v-icon>
                          </v-avatar>

                          <div>
                            <h3 class="text-xl font-weight-medium mb-n1">
                              {{ kFormatter(1201) }}
                            </h3>
                            <span>Dienstalter</span>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text> -->
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="7">
              <v-skeleton-loader
                v-if="dataIsLoading"
                elevation="2"
                type="table-heading, table-tbody"
              ></v-skeleton-loader>
              <v-card v-else>
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2"
                    >mdi-card-account-details</v-icon
                  >Allgemein</v-card-title
                >

                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr class="v-row-group__header">
                        <th :colspan="2">Zur Person</th>
                      </tr>
                      <tr v-if="user.salutation || user.letterSalutation">
                        <td class="font-weight-medium">Anrede/Briefanrede</td>
                        <td>
                          {{ user.salutation
                          }}<span
                            v-if="user.salutation && user.letterSalutation"
                            >/</span
                          >{{ user.letterSalutation }}
                        </td>
                      </tr>
                      <tr v-if="user.title">
                        <td class="font-weight-medium">Titel</td>
                        <td>{{ user.title }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">Vorname</td>
                        <td>{{ user.firstName }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">Nachname</td>
                        <td>{{ user.lastName }}</td>
                      </tr>

                      <tr v-if="user.birthdate">
                        <td class="font-weight-medium">Geburtsdatum (Alter)</td>
                        <td>
                          {{ timestampConverter(user.birthdate) }} ({{
                            getAge(user.birthdate.toDate())
                          }})
                        </td>
                      </tr>
                      <tr v-if="user.birthplace">
                        <td class="font-weight-medium">Geburtsort</td>
                        <td>{{ user.birthplace }}</td>
                      </tr>
                      <tr class="v-row-group__header">
                        <th :colspan="2">Zur Mitgliedschaft</th>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">Personalnummer</td>
                        <td>
                          <pre>{{ user.personnelNumber }}</pre>
                        </td>
                      </tr>
                      <tr v-if="user.dutyPosition.title">
                        <td class="font-weight-medium">Dienststellung</td>
                        <td>
                          <img
                            v-if="user.dutyPosition.badgeUrl.size125x125"
                            contain
                            :src="user.dutyPosition.badgeUrl.size125x125"
                            height="25px"
                            class="mr-2 my-2 d-block"
                          />
                          {{ user.dutyPosition.title
                          }}<span v-if="user.dutyPosition.since"
                            ><br />(seit
                            {{
                              timestampConverter(user.dutyPosition.since)
                            }})</span
                          >
                        </td>
                      </tr>
                      <tr v-if="user.rank.title">
                        <td class="font-weight-medium">Dienstgrad</td>
                        <td>
                          <img
                            v-if="user.rank.badgeUrl.size125x125"
                            contain
                            :src="user.rank.badgeUrl.size125x125"
                            height="25px"
                            class="mr-2 my-2 d-block"
                          />

                          {{ user.rank.title
                          }}<span v-if="user.rank.since"
                            ><br />(seit
                            {{ timestampConverter(user.rank.since) }})</span
                          >
                        </td>
                      </tr>
                      <tr v-if="user.memberSince">
                        <td class="font-weight-medium">Mitglied seit</td>
                        <td>{{ timestampConverter(user.memberSince) }}</td>
                      </tr>
                      <tr v-if="user.memberUntil">
                        <td class="font-weight-medium">Mitglied bis</td>
                        <td>{{ timestampConverter(user.memberUntil) }}</td>
                      </tr>
                      <tr v-if="user.note" class="v-row-group__header">
                        <th :colspan="2">Sonstiges</th>
                      </tr>
                      <tr v-if="user.note">
                        <td class="font-weight-medium">Notizen</td>
                        <td>{{ user.note }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import PersonnelRecordStatusChip from "@/components/member-management/personnel-records/PersonnelRecordStatusChip.vue";
export default {
  name: "member-management-personnel-records-details-general",
  components: { PersonnelRecordStatusChip, SupportTools },
  data() {
    return {
      dataIsLoading: true,
      user: {
        id: null,
        salutation: null,
        letterSalutation: null,
        title: null,
        firstName: null,
        lastName: null,
        birthday: null,
        birthdate: null,
        photoUrl: null,

        memberSince: null,
        memberUntil: null,
        dutyPosition: {
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          title: null,
          since: null,
        },
        rank: {
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          title: null,
          since: null,
        },
        status: null,
        division: null,
      },
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "user", data: this.user }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_general")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.user = doc.data();
            this.dataIsLoading = false;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    kFormatter(num, fraction = 2) {
      return num > 999 ? `${(num / 1000).toFixed(fraction)}k` : num;
    },
    getAge(birthdate) {
      return new Date().getFullYear() - new Date(birthdate).getFullYear();
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
