<template>
  <div>
    <NavigationBar
      titel="Datensatz bearbeiten"
      :tabs="tabs"
      :activeTab="activeTab"
      :actions="actions"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
// import Subheader from "@/components/_systemwide/Subheader.vue";
import {
  // MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update,
} from "@/data/permission-types.js";

export default {
  name: "member-management-personnel-records-edit",
  components: {
    NavigationBar,
    // Subheader,
  },
  data() {
    return {
      activeTab: `general`,
      actions: [
        {
          title: "Bearbeitung verlassen",
          icon: "mdi-close-circle",
          actionStyle: "textBtnSubmit",
          color: "error",
          function: () => {
            this.$router.push({
              name: "member-management-personnel-records-details-general",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              },
            });
          },
        },
      ],
    };
  },
  computed: {
    tabs() {
      const allTabs = [
        {
          id: 1,
          name: "Stammdaten",
          route: `general`,
          collectionId: "personnelRecords_general",
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update}`,
        },
        {
          id: 2,
          name: "Kontaktdaten",
          route: `contact`,
          collectionId: "personnelRecords_contact",
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update}`,
        },
        {
          id: 3,
          name: "Chronik",
          route: `chronicle`,
          collectionId: "personnelRecords_chronicle",
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update}`,
        },
        {
          id: 4,
          name: "Fertigkeiten",
          route: `certificates`,
          collectionId: "personnelRecords_certificates",
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update}`,
        },
        // { id: 5, name: "Untersuchungen", route: `physicals` },
        {
          id: 6,
          name: "Notfallkontakte",
          route: `emergency-contacts`,
          collectionId: "personnelRecords_emergencyContacts",
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update}`,
        },
        {
          id: 7,
          name: "Abrechnung",
          route: `accounting`,
          collectionId: "personnelRecords_accounting",
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update}`,
        },
        // { id: 8, name: "Inventar", route: `inventory` },
        {
          id: 9,
          name: "Jugendfeuerwehr",
          route: `youth-group`,
          collectionId: "personnelRecords_youthGroup",
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update}`,
        },
        { id: 10, name: "Änderungshistorie", route: ``, disabled: true },
      ];
      return allTabs.filter((tab) => {
        return this.checkPermission(tab.permission);
      });
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      // this.getPersonnelRecord();
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
  },
};
</script>

<style></style>
