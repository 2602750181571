<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      :backRoute="backRoute"
      :tabs="tabs"
      :activeTab="activeTab"
      @active-tab-updated="activeTab = $event"
      showBackBtn
    ></NavigationBar>
    <!-- <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-card></v-card>
            </v-col>
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7"></div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section> -->
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
// import Subheader from "@/components/_systemwide/Subheader.vue";
import { MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update } from "@/data/permission-types.js";

export default {
  name: "member-management-personnel-records-details",
  components: {
    NavigationBar,
    // Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "E-Mail senden",
              icon: "mdi-email",
              disabled: true,
              function: this.createItem,
            },
            {
              title: "Datensatz sperren",
              icon: "mdi-lock",
              disabled: true,
              function: this.createMultiple,
            },
            { divider: true },
            {
              title: "Kontakt als vCard exportieren ",
              icon: "mdi-file-export",
              disabled: true,
              function: this.createFromCSV,
            },
            {
              title: "Drucken",
              icon: "mdi-printer",
              disabled: true,
              function: this.printItem,
            },
          ],
        },

        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      activeTab: `general`,
      tabs: [
        { id: 1, name: "Stammdaten", route: `general` },
        { id: 2, name: "Kontaktdaten", route: `contact` },
        { id: 3, name: "Chronik", route: `chronicle`, disabled: true },
        { id: 4, name: "Fertigkeiten", route: `certificates` },
        // { id: 5, name: "Untersuchungen", route: `physicals` },
        { id: 6, name: "Notfallkontakte", route: `emergency-contacts` },
        { id: 7, name: "Abrechnung", route: `accounting`, disabled: true },
        // { id: 8, name: "Inventar", route: `inventory` },
        {
          id: 9,
          name: "Jugendfeuerwehr",
          route: `youth-group`,
          disabled: true,
        },
        {
          id: 10,
          name: "Änderungshistorie",
          route: `changelog`,
          disabled: true,
        },
      ],
    };
  },
  computed: {
    backRoute() {
      return this.$route.path.includes ===
        "/personnel-records/" + this.$route.params.itemId
        ? "member-management-personnel-records"
        : "";
    },
  },
  methods: {
    editItem() {
      this.$router.push({
        name: `member-management-personnel-records-edit-${
          this.activeTab || "general"
        }`,
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
  },
};
</script>

<style></style>
