<template>
  <div>
    <section>
      <v-container>
        <v-row class="match-height">
          <v-col cols="12" sm="8">
            <v-card>
              <v-card-title class="body-2 v-card__subtitle"
                ><v-icon left small class="mr-2"
                  >mdi-card-account-details</v-icon
                >Allgemein</v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.trim="data.personnelNumber"
                        label="Personalnummer"
                        hide-details="auto"
                        outlined
                        prepend-inner-icon="mdi-identifier"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model.trim="data.salutation"
                        label="Anrede"
                        :items="salutationOptions"
                        hide-details="auto"
                        clearable
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model.trim="data.letterSalutation"
                        label="Briefanrede"
                        :items="letterSalutationOptions"
                        hide-details="auto"
                        clearable
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model.trim="data.title"
                        label="Titel"
                        :items="titleOptions"
                        clearable
                        hide-details="auto"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.trim="data.firstName"
                        label="Vorname"
                        readonly
                        disabled
                        :rules="[rules.required]"
                        hide-details="auto"
                        outlined
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.trim="data.lastName"
                        label="Nachname"
                        readonly
                        disabled
                        :rules="[rules.required]"
                        hide-details="auto"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model.trim="data.birthdate"
                        label="Geburtsdatum"
                        type="date"
                        clearable
                        hide-details="auto"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model.trim="data.birthplace"
                        label="Geburtsort"
                        hide-details="auto"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="success"
                    :loading="helpers.generalInfoForm.isLoading"
                    :disabled="!helpers.generalInfoForm.isValid"
                    @click="updateGeneralInfo()"
                  >
                    <v-icon left> mdi-content-save </v-icon>
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card disabled>
              <v-card-title class="body-2 v-card__subtitle"
                ><v-icon left small class="mr-2">mdi-face-man</v-icon
                >Profilfoto<v-spacer></v-spacer
                ><v-menu open-on-hover bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed small v-bind="attrs" v-on="on"
                      ><v-icon small left>mdi-information</v-icon
                      >Empfehlungen</v-btn
                    >
                  </template>

                  <v-card>
                    <v-card-text>
                      <span class="font-weight-medium text-subtitle-1"
                        >Empfehlungen:</span
                      >
                      <ul>
                        <li>Maximale Dateigröße: 3.5 MB</li>
                        <li>Datei mit hoher Auflösung: z.B. 1500px x 1500px</li>
                        <li>Bild mit quadratischem Seitenverhältnis: 1 : 1</li>
                        <li>
                          Zulässige Formate:
                          <code class="mr-1">.png</code>
                          <code class="mr-1">.jpeg</code>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12"
                      ><v-avatar
                        size="140"
                        :color="
                          $vuetify.theme.dark
                            ? 'grey darken-3'
                            : 'grey lighten-3'
                        "
                        rounded
                        class="mr-4"
                      >
                        <v-img></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12"
                      ><v-btn depressed class="mr-4"
                        ><v-icon left>mdi-image-edit</v-icon>Ersetzen</v-btn
                      ><v-btn depressed color="error"
                        ><v-icon left>mdi-delete</v-icon>Löschen</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="body-2 v-card__subtitle"
                ><v-icon left small class="mr-2">mdi-dots-horizontal</v-icon
                >Sonstiges</v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4"
                      ><v-select
                        v-model.trim="data.dutyPosition"
                        :items="dutyPositions"
                        label="Dienststellung"
                        hide-details="auto"
                        persistent-hint
                        clearable
                        item-text="title"
                        item-value="id"
                        return-object
                        hint="Wird von Ereignis in Chronik überschrieben."
                        no-data-text="Keine Dienststellungen vorhanden."
                        outlined
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip label outlined>
                            <img
                              contain
                              height="30px"
                              :src="item.badgeUrl.size125x125"
                              class="mr-2 py-1"
                            />
                            <div class="font-weight-medium">
                              {{ item.title }}
                            </div>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar tile>
                            <v-img contain :src="item.badgeUrl.size125x125" />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">{{
                              item.title
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.shortTitle
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select></v-col
                    >
                    <v-col cols="12" sm="6" md="4"
                      ><v-select
                        v-model.trim="data.rank"
                        :items="ranks"
                        label="Dienstgrad"
                        hide-details="auto"
                        clearable
                        item-text="title"
                        item-value="id"
                        return-object
                        persistent-hint
                        hint="Wird von Ereignis in Chronik überschrieben."
                        no-data-text="Keine Dienstgrade vorhanden."
                        outlined
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip label outlined>
                            <img
                              contain
                              height="30px"
                              :src="item.badgeUrl.size125x125"
                              class="mr-2 py-1"
                            />
                            <div class="font-weight-medium">
                              {{ item.title }}
                            </div>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar tile>
                            <v-img contain :src="item.badgeUrl.size125x125" />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">{{
                              item.title
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.shortTitle
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select></v-col
                    >
                    <v-col cols="12" sm="6" md="4"
                      ><v-select
                        v-model.trim="data.status"
                        label="Status"
                        :items="statusOptions"
                        hide-details="auto"
                        persistent-hint
                        hint="Wird von Ereignis in Chronik überschrieben."
                        outlined
                      ></v-select
                    ></v-col>
                    <!-- <v-col cols="12" sm="6" md="4"
                      ><v-select
                        label="Einheit"
                        :items="divisionOptions"
                        hide-details="auto"
                        clearable
                        outlined
                      ></v-select
                    ></v-col> -->
                    <v-col cols="12">
                      <v-textarea
                        v-model.trim="data.note"
                        label="Notizen"
                        hide-details="auto"
                        outlined
                        :rows="3"
                        auto-grow
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="success"
                    :loading="helpers.additionalInfoForm.isLoading"
                    :disabled="!helpers.additionalInfoForm.isValid"
                    @click="updateAdditionalInfo()"
                  >
                    <v-icon left> mdi-content-save </v-icon>
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-snackbar
      v-model="helpers.snackbar.model"
      class="pb-4"
      bottom
      app
      timeout="2000"
      ><v-icon
        :color="helpers.snackbar.type === 'success' ? 'success' : 'error'"
        class="mr-1"
        >{{
          helpers.snackbar.type === "success" ? "mdi-check-circle" : "mdi-error"
        }}</v-icon
      >
      {{ helpers.snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, auth, Timestamp, FieldValue } from "@/firebase";
import { handleFirestoreError } from "@/util/firebase/FirebaseErrorHandling.js";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_RANKS, GET_DUTY_POSITIONS } from "@/store/action-types.js";

export default {
  name: "member-management-personnel-records-details-general",
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      salutationOptions: [
        { text: "Herr", value: "Herr" },
        { text: "Frau", value: "Frau" },
      ],
      letterSalutationOptions: [
        { text: "Herrn", value: "Herrn" },
        { text: "Frau", value: "Frau" },
      ],
      titleOptions: [
        { text: "Dr.", value: "Dr." },
        { text: "Prof.", value: "Prof." },
        { text: "Prof. Dr.", value: "Prof. Dr." },
      ],
      statusOptions: [
        { text: "Aktiv", value: "active", color: "#4CAF50" },
        { text: "Beurlaubt", value: "onLeave", color: "#FF9800" },
        { text: "Ausgetreten", value: "resigned", color: "#795548" },
        { text: "Im Übertritt", value: "transfer", color: "#CDDC39" },
        { text: "Entlassen", value: "dismissed", color: "#F44336" },
        { text: "Verstorben", value: "deceased", color: "#9E9E9E" },
      ],

      data: {
        // about the person
        salutation: null,
        letterSalutation: null,
        title: null,
        firstName: null,
        lastName: null,
        birthdate: null,
        birthplace: null,
        photoUrl: null,
        // about the membership
        personnelNumber: null,
        dutyPosition: null,
        rank: null,
        memberSince: null,
        memberUntil: null,
        status: null,
        note: null,
      },
      helpers: {
        generalInfoForm: {
          isValid: true,
          isLoading: false,
        },
        additionalInfoForm: {
          isValid: true,
          isLoading: false,
        },
        snackbar: {
          model: false,
          type: "success",
          message: "",
        },
      },
    };
  },
  computed: {
    ranks() {
      const ranks = this.$store.state.memberManagement.ranks;
      return ranks
        .map((rank) => {
          return {
            title: rank.title,
            shortTitle: rank.shortTitle,
            id: rank.meta.id,
            badgeUrl: rank.badgeUrl,
            sortKey: rank.sortKey,
          };
        })
        .sort((a, b) => a.sortKey - b.sortKey);
    },
    dutyPositions() {
      const dutyPositions = this.$store.state.memberManagement.dutyPositions;
      return dutyPositions
        .map((dutyPosition) => {
          return {
            title: dutyPosition.title,
            shortTitle: dutyPosition.shortTitle,
            id: dutyPosition.meta.id,
            badgeUrl: dutyPosition.badgeUrl,
            sortKey: dutyPosition.sortKey,
          };
        })
        .sort((a, b) => a.sortKey - b.sortKey);
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_RANKS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DUTY_POSITIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_general")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    updateGeneralInfo() {
      this.helpers.generalInfoForm.isLoading = true;
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_general")
        .doc(this.$route.params.itemId)
        .update({
          personnelNumber: this.data.personnelNumber,
          salutation: this.data.salutation,
          letterSalutation: this.data.letterSalutation,
          title: this.data.title,
          birthdate: this.data.birthdate
            ? this.convertToTimestamp(this.data.birthdate, "00:00")
            : null,
          birthplace: this.data.birthplace,
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        })
        .then(() => {
          this.helpers.snackbar.message = "Aktualisierung erfolgreich.";
          this.helpers.snackbar.type = "success";
          this.helpers.generalInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.snackbar.message = handleFirestoreError(error);
          this.helpers.snackbar.type = "error";
          this.helpers.generalInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        });
    },
    updateAdditionalInfo() {
      this.helpers.additionalInfoForm.isLoading = true;
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_general")
        .doc(this.$route.params.itemId)
        .update({
          note: this.data.note,
          rank: {
            title: this.data.rank.title,
            shortTitle: this.data.rank.shortTitle,
            id: this.data.rank.id,
            badgeUrl: this.data.rank.badgeUrl,
            sortKey: this.data.rank.sortKey,
            since: null,
          },
          dutyPosition: {
            title: this.data.dutyPosition.title,
            shortTitle: this.data.dutyPosition.shortTitle,
            id: this.data.dutyPosition.id,
            badgeUrl: this.data.dutyPosition.badgeUrl,
            sortKey: this.data.dutyPosition.sortKey,
            since: null,
          },
          status: this.data.status,
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        })
        .then(() => {
          this.helpers.snackbar.message = "Aktualisierung erfolgreich.";
          this.helpers.snackbar.type = "success";
          this.helpers.additionalInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.snackbar.message = handleFirestoreError(error);
          this.helpers.snackbar.type = "error";
          this.helpers.additionalInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        });
    },
  },
};
</script>

<style></style>
