<template>
  <div>
    <NavigationBar
      titel="Personal"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <TippCard
        hintID="Mitgliederverwaltung_Personal_1_V1"
        text="Für jeden Benutzer kann ein Mitgliedsprofil erstellt werden, um die Mitgliederverwaltung des Personals zu organisieren. Jeder Benutzer pflegt und verwaltet seinen Eintrag grundsätzlich selbstständig. Je nach Einstellung können für Einträge Genehmiger festgelegt werden. Bei Änderung des Eintrags wird die festgelegte Person/-gruppe informiert und eine Genehmigung des Eintrages eingefordert."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-account-details" title="Mitglieder">
                <template v-slot:actions>
                  <v-btn-toggle dense v-model="showTable" mandatory>
                    <v-btn outlined icon :value="false">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-view-grid</v-icon
                      >
                    </v-btn>
                    <v-btn depressed outlined icon :value="true">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-table</v-icon
                      >
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </Subheader>
              <div v-if="!showTable" class="mt-7">
                <v-data-iterator
                  :items="records"
                  item-key="user.uid"
                  sort-by="firstName"
                  :sort-desc="false"
                  :footer-props="footerProps"
                  :items-per-page="25"
                  no-data-text="Keine Einträge vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row class="match-height">
                      <v-col
                        v-for="item in props.items"
                        :key="item.user.uid"
                        cols="12"
                        sm="12"
                        md="4"
                      >
                        <v-card link @click="routeDetails(item)">
                          <v-list>
                            <v-list-item>
                              <v-avatar
                                size="62"
                                :color="
                                  $vuetify.theme.dark
                                    ? 'grey darken-3'
                                    : 'grey lighten-3'
                                "
                                rounded
                                class="mr-5"
                                ><v-img
                                  v-if="item.photoUrl"
                                  contain
                                  :src="item.photoUrl"
                                ></v-img
                                ><v-icon v-else size="34"
                                  >mdi-account-circle</v-icon
                                >
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="text-h6 mb-2"
                                  >{{ item.firstName }}
                                  {{ item.lastName }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  v-if="
                                    item.dutyPosition.title || item.rank.title
                                  "
                                  ><span v-if="item.dutyPosition.title">{{
                                    item.dutyPosition.title
                                  }}</span>
                                  <br
                                    v-if="
                                      item.dutyPosition.title && item.rank.title
                                    "
                                  />
                                  <span v-if="item.rank.title">{{
                                    item.rank.title
                                  }}</span></v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
              <div v-else class="mt-7">
                <personnel-records-data-table
                  :personnelRecords="records"
                ></personnel-records-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.recordCreation.dialogModel"
        @close="toggleRecordCreationDialog()"
        title="Neues Mitglied anlegen"
        width="m"
        :errorMessage="helpers.recordCreation.errorMessage"
        :btnAction="startRecordCreation"
        :btnDisabled="!helpers.recordCreation.formIsValid"
        :loading="helpers.recordCreation.loading"
        btnText="Erstellen"
      >
        <template v-slot:content>
          <v-alert text color="warning" type="info">
            <div class="title">Hinweis</div>
            <div class="mt-2">
              Einträge werden automatisch für alle Mitglieder der Organisation
              erstellt. Zusätzliche Einträge sollten nur erstellt werden für
              Personen ohne eigenen Zugang/eigenes Benutzerkonto.
            </div>
          </v-alert>
          <v-form
            v-model="helpers.recordCreation.formIsValid"
            @submit.prevent="startRecordCreation()"
          >
            <personnel-record-creation-editor
              v-model="helpers.personnelRecordBaseData"
            ></personnel-record-creation-editor>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { functions } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import PersonnelRecordsDataTable from "@/components/member-management/personnel-records/PersonnelRecordsDataTable.vue";
import PersonnelRecordCreationEditor from "@/components/member-management/personnel-records/PersonnelRecordCreationEditor.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { MEMBER_MANAGEMENT_PERSONNEL_RECORDS_create } from "@/data/permission-types.js";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_PERSONNEL_RECORDS } from "@/store/action-types.js";

export default {
  name: "member-management-personnel-records",
  components: {
    NavigationBar,
    Subheader,
    PersonnelRecordsDataTable,
    SupportTools,
    CustomDialog,
    PersonnelRecordCreationEditor,

    TippCard,
  },
  data() {
    return {
      showTable: true,
      search: "",
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_create}`,
          actionStyle: "textBtn",
          disabled: true,
          function: this.toggleRecordCreationDialog,
        },
      ],
      helpers: {
        recordCreation: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          errorMessage: "",
        },
        personnelRecordBaseData: {
          firstName: null,
          lastName: null,
          personnelNumber: null,
          status: null,
          division: null,
        },
      },
    };
  },
  computed: {
    records() {
      return this.$store.state.memberManagement.personnelRecords;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "records", data: this.records }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_PERSONNEL_RECORDS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    startRecordCreation() {
      if (this.helpers.recordCreation.formIsValid) {
        this.helpers.recordCreation.loading = true;

        const callFunction = functions.httpsCallable(
          "memberManagement-createPersonnelRecordFromInput"
        );
        console.log({
          organizationId: this.$route.params.organizationId,
          firstName: this.helpers.personnelRecordBaseData.firstName,
          lastName: this.helpers.personnelRecordBaseData.lastName,
          personnelNumber: this.helpers.personnelRecordBaseData.personnelNumber,
          status: this.helpers.personnelRecordBaseData.status,
          division: this.helpers.personnelRecordBaseData.division,
        });
        callFunction({
          organizationId: this.$route.params.organizationId,
          firstName: this.helpers.personnelRecordBaseData.firstName,
          lastName: this.helpers.personnelRecordBaseData.lastName,
          personnelNumber: this.helpers.personnelRecordBaseData.personnelNumber,
          status: this.helpers.personnelRecordBaseData.status,
          division: this.helpers.personnelRecordBaseData.division,
        });
        callFunction()
          .then((result) => {
            console.log(result.data);
            if (result.data.success) {
              this.toggleRecordCreationDialog();
              (this.helpers.personnelRecordBaseData = {
                firstName: null,
                lastName: null,
                personnelNumber: null,
                status: null,
                division: null,
              }),
                (this.helpers.recordCreation.loading = false);
              this.helpers.recordCreation.snackbar.model = true;
              this.helpers.recordCreation.snackbar.text =
                "Datensatz für Eingabe erfolgreich erstellt";
            } else {
              this.helpers.recordCreation.loading = false;
              this.helpers.recordCreation.errorMessage =
                "Fehler: Versuche es später erneut.";
            }
          })
          .catch((error) => {
            this.helpers.recordCreation.loading = false;
            console.error(error.errorMsg);
            console.error(error);
            this.helpers.recordCreation.errorMessage = error.message;
          });
      } else {
        alert("Fehler: Prüfe Deine Daten und versuche es erneut");
      }
    },

    toggleRecordCreationDialog() {
      this.helpers.recordCreation.dialogModel =
        !this.helpers.recordCreation.dialogModel;
    },
    routeDetails(item) {
      this.$router.push({
        name: "member-management-personnel-records-details-general",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.user.uid,
        },
      });
    },
  },
};
</script>
